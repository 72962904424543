<template>
  <div class="no-padding">
    <div style="height: 100%;">

      <!-- Add membership begin -->
        <b-form ref="form" @submit.prevent="on_submit" class="mt-8">
          <b-row>
            <b-col cols="12">
              <b-form-group
                label-size="sm"
                label-cols="4"
                content-cols="8"
                :label="$t('COMPANY.COMPANY')"
              >
                <b-form-select
                  v-model="local_education.company_id"
                  :options="company_options"
                  :disabled="false"
                  :state="company_current_id_state"
                />
              </b-form-group>
            </b-col>
          </b-row>


          <b-row>
            <b-col cols="12">
              <b-form-group
                label-size="sm"
                label-cols="4"
                content-cols="8"
                :label="$t('MEMBER_EDUCATION.ID')">
                  <b-form-input
                    v-model="local_education.id"
                    :disabled="true"
                  />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group
                label-size="sm"
                label-cols="4"
                content-cols="8"
                :label="$t('MEMBER_EDUCATION.CREATED_AT')">
                  <b-form-input
                    v-model="local_education.created_at"
                    :disabled="true"
                  />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group
                label-size="sm"
                label-cols="4"
                content-cols="8"
                :label="$t('MEMBER_EDUCATION.SOURCE')"
              >
                <b-form-select
                  v-model="local_education.source"
                  :disabled="true"
                  :options="source_options"
                  :state="source_state"
                />
              </b-form-group>
            </b-col>
          </b-row>


          <b-row>
            <b-col cols="12">
              <b-form-group
                label-size="sm"
                label-cols="4"
                content-cols="8"
                :label="$t('MEMBER_EDUCATION.EDUCATION_ID')"
              >
                <Multiselect
                  class="ml-2 multiselect-blue"
                  v-model="local_education.education_id"
                  ref="educations_multiselect"
                  :placeholder="$t('ADVANCED_SEARCH.EDUCATIONS.SEARCH_EDUCATION')"
                  :close-on-select="false"
                  :filter-results="false"
                  :min-chars="0"
                  :resolve-on-load="false"
                  :delay="0"
                  :searchable="true"
                  :showNoOptions="false"
                  :noOptionsText="$t('ADVANCED_SEARCH.NO_RESULTS')"
                  :noResultsText="$t('ADVANCED_SEARCH.NO_RESULTS')"
                  :options="async function(query) {
                    return await fetch_education_options(query);
                  }"
                  @change="changed"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12">
              <b-form-group
                label-size="sm"
                label-cols="4"
                content-cols="8"
                :label="$t('PERIOD.PERIOD')"
              >
                <b-form-select
                  v-model="local_education.period_id"
                  :options="period_options"
                  :state="period_id_state"
                  @change="on_period_change"
                />
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col>
              <required-fields-info/>
            </b-col>
          </b-row>

          <p style="margin-top: 42px;"></p>

          <b-row>
            <b-col>

              <RightModalSaveAndCloseButtons
                :text="$t('COMMON.SAVE')"
                :spin="true"
                @clicked="on_submit"
                @close="$emit('close')"
                ref="save_button"
              />

            </b-col>
          </b-row>
        </b-form>

    </div>
  </div>
  </template>


<script>
import axios from 'axios';

import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { mapGetters } from 'vuex';

import RightModalSaveAndCloseButtons from '@/view/components/buttons/RightModalSaveAndCloseButtons.vue';
import RequiredFieldsInfo from '@/view/components/RequiredFieldsInfo.vue';
import Multiselect from '@vueform/multiselect/dist/multiselect.vue2.js'
import { useVuelidate } from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { validate_state } from '@/core/services/validations.service';


export default {
  name: 'MemberEducationEditor',

  props: ['item'],
  emits: ['created', 'updated', 'close'],
  mixins: [ toasts ],

  components: {
    RightModalSaveAndCloseButtons,
    RequiredFieldsInfo,
    Multiselect,
  },

  watch: {
    'local_education.company_id'() {
      if (this.local_education.company_id) {
        this.education_pagination();
      }
    },
    item: {
      deep: true, // check properties of the form, not just the form reference (example.name for example)
      handler(val) {
        this.local_education = { ...this.item };
      }
    },
  },
  setup() {
    return { v$: useVuelidate() }
  },
  validations() {
    return {
      local_education: {
        company_id: { required },
        source: { required },
        education_id: { required },
        period_id: { required },
      }
    }
  },
  computed: {
    ...mapGetters(['currentCompanyId', 'currentPeriodId', 'companies', 'periods', 'settings']),
    company_current_id_state() {
      return validate_state({ dirty: this.v$.local_education.company_id.$dirty, error: this.v$.local_education.company_id.$error })
    },
    source_state() {
      return validate_state({ dirty: this.v$.local_education.source.$dirty, error: this.v$.local_education.source.$error })
    },
    education_id_state() {
      return validate_state({ dirty: this.v$.local_education.education_id.$dirty, error: this.v$.local_education.education_id.$error })
    },
    period_id_state() {
      return validate_state({ dirty: this.v$.local_education.period_id.$dirty, error: this.v$.local_education.period_id.$error })
    },
    company_options() {
      return this.companies.map((company) => {
        return {
          text: company.name,
          value: company.company_id
        };
      });
    },

    source_options() {
      return [
        { text: this.$t('MEMBER_EDUCATION.CREATE_SOURCES.LADOK'), value: 'LADOK' },
        { text: this.$t('MEMBER_EDUCATION.CREATE_SOURCES.ADMIN'), value: 'ADMIN' },
        { text: this.$t('MEMBER_EDUCATION.CREATE_SOURCES.MEMBER'), value: 'MEMBER' },
      ]
    },

    creating() {
      return this.item.id === null;
    },
  },

  async mounted() {
    if (this.item) {
      this.local_education = { ...this.item };
    }

    await this.education_pagination()

    this.period_options = this.periods.map((item) => ({ text: item.name, value: item.id }));
    this.education_options = this.educations.map((item) => ({ text: item.code + ' ' + item.name, value: item.id }));
  },

  methods: {
    changed() {
      
    },

    on_period_change() {
      if (this.periods.length === 0) {
        return;
      }
    },

    async education_pagination() {
      try {
        const res = await axios.post('/education/pagination',
          {
            options: {
              company_id: this.local_education.company_id,
            }
          }
        );

        if (res.status !== 200) {
          console.error('pagination: invalid status', res.status);
          return;
        }

        this.educations = res.data;
      } catch(err) {
        console.error('error get_pagination educations', err);
      }
    },

    async load_educations() {
      try {
        const response = await axios.get(`/education?company_id=${this.local_education.company_id}`);
        this.educations = response.data;
      }
      catch (err) {
        console.error('error loading educations', err);
      }
    },

    reset_validation() {
      if (this.item) {
        this.v$.$reset();
      }
    },
    async validate() {
      return await this.v$.$validate();
    },

    async on_submit() {
      const valid = await this.v$.$validate();

      if (!valid) {
        this.v$.$touch();
        this.$refs['save_button'].stop();
        return
      }

      if (this.creating) {
        await this.create_member_education();
      }
      else {
        await this.update_member_education();
      }

      this.$refs['save_button'].stop();
    },

    async post_member_education(item) {
      try {
        const response = await axios.post(`/member_education`, item);

        if (response.status === 201) {
          return response.data;
        }

        throw `invalid response code ${response.status}`;
      }
      catch (err) {
        throw `catched error: ${err}`;
      }
    },

    async put_member_education(item) {
      try {
        const response = await axios.put(`/member_education/${item.id}`, item);

        if (response.status === 201) {
          return response.data;
        }

        throw `invalid response code ${response.status}`;
      }
      catch (err) {
        throw `catched error: ${err}`;
      }
    },

    async create_member_education() {
      try {

        const item = await this.post_member_education(this.local_education);

        this.local_education.id = item.id;
        this.local_education.status = item.status;
        this.local_education.created_at = item.created_at;

        this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.CREATED'));
        this.$emit('created', this.local_education);
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_CREATE'));
      }
    },

    async fetch_education_options(query) {
      const res = await axios.post(`/education/pagination`, { options: { text: query }, page: 1, limit: 100 });

      if (res.status === 200) {
        return res.data.map(item => ({ label: item.code + ' ' + item.name, value: item.id }));
      }

      return [];
    },


    async update_member_education() {
      try {

        await this.put_member_education(this.local_education);

        this.toastr('success', this.$t('COMMON.OK'), this.$t('COMMON.UPDATED'));
        this.$emit('updated', this.local_education);
      }
      catch (err) {
        console.error(err);
        this.toastr('danger', this.$t('COMMON.ERROR'), this.$t('COMMON.UNABLE_CREATE'));
      }
    },

  },


  data() {
    return {
      local_education: {},
      education_options: [],
      period_options: []
    };
  }
};

</script>

<style lang="scss" scoped>
@import "@/assets/sass/components/forms/_compact.scss";
</style>
